<template>
  <v-app>
    <v-snackbar v-model="snackbar">
      <v-icon class="primary--text pa-1">mdi-alert</v-icon>
      <span class="white--text">Mauvais e-mail ou mot de passe. Veuillez réessayer.</span>
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">Close</v-btn>
      </template>
    </v-snackbar>

    <v-container class="fill-height" fluid>
      <v-row align="center" justify="center">
        <v-col style="margin-top: -100px" cols="12" sm="8" md="6">
          <v-card :loading="loading" class="opa elevation-12">
            <v-toolbar color="primary" dark flat>
              <v-toolbar-title>Modification du mot de passe</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-btn icon large @click="back" target="_blank" v-on="on">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>
                <span class="white--text">Réinitialiser le mot de passe</span>
              </v-tooltip>
            </v-toolbar>

            <div v-if="finished" class="pa-2">
              <v-alert class="pa-2" type="info">
                Vous allez recevoir les instructions par e-mail afin de réinitialiser votre mot de passe
              </v-alert>
            </div>
            <v-card-text v-if="!finished">
              <v-form v-model="valid" ref="form" :lazy-validation="false">
                <v-text-field @input="verifyEmail" :error="existEmail"
                  :prepend-icon='existEmail ? "mdi-alert" : "mdi-email"' v-model="email" label="Votre e-mail" name="Email"
                  :rules="emailRules" prepend-icon="mdi-email" type="email"></v-text-field>
                <v-alert v-if="existEmail" type="error">Votre e-mail {{ this.email }} n'existe pas sur notre
                  plateforme</v-alert>
                <v-row>
                </v-row>
              </v-form>
            </v-card-text>
            <v-card-actions v-if="!finished" class="d-flex justify-center align-center pa-2">
              <v-row>
                <v-col>
                  <v-btn type="submit" :disabled="!valid" @click="reset" color="primary">
                    <v-icon>mdi-lock</v-icon>Réinitialiser le mot de passe
                  </v-btn>
                </v-col>
                <v-col>
                  <v-btn outlined @click="back" color="primary">
                    <v-icon>mdi-close</v-icon>Annuler
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>

            <v-card-actions v-if="finished">
              <v-row>
                <v-col></v-col>
                <v-col>
                  <v-btn @click="back" color="success" dark>
                    <v-icon>mdi-check-circle-outline</v-icon>D'accord
                  </v-btn>
                </v-col>
              </v-row>
            </v-card-actions>




          </v-card>
        </v-col>
      </v-row>

    </v-container>
  </v-app>
</template>


<script>
import xhr from "../plugins/axios";
import { initializeApp } from 'firebase/app';
import 'firebase/compat/auth';
import { debounce } from 'lodash';

// TODO: Replace the following with your app's Firebase project configuration
const firebaseConfig = {
  apiKey: "AIzaSyAI1ZOHrb1zKj-f1Iu6r6BTG87t5qbfUeE",
  authDomain: "cartovia-3a989.firebaseapp.com",
  projectId: "cartovia-3a989",
  storageBucket: "cartovia-3a989.appspot.com",
  messagingSenderId: "1080134626602",
  appId: "1:1080134626602:web:13828952dd2badd70b4253"
};

import { getAuth, signInWithPopup, GoogleAuthProvider } from "firebase/auth";

const firebase = initializeApp(firebaseConfig);

export default {
  name: "Login",
  data() {
    return {
      existEmail: false,
      snackbar: false,
      finished: false,
      loading: false,
      valid: true,
      email: "",
      password: "",
      nameRules: [
        (v) => !!v || "Mot de passe obligatoire",
        (v) =>
          (v && v.length >= 8) ||
          "Mot de passe doit faire au moins 8 caractères",
      ],
      emailRules: [
        (v) => !!v || "E-mail obligatoire",
        (v) => /.+@.+\..+/.test(v) || "E-mail doit être valide",
      ],
    };
  },
  created() {
  },
  methods: {
    verifyEmail: debounce(async function () {
      const regex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;

      if (this.email.length > 2 && regex.test(this.email)) {
        this.existEmail = false
        this.loading = true
        let res = await xhr.post("/check-email", { email: this.email.trim() })

        if (res.data != true) {
          this.existEmail = true
        } else {
          this.existEmail = false

          // this.updateEmail(this.email);
        }
        this.loading = false

      }

    }, 2000),
    back() {
      this.$router.push('/login')
    },
    async reset() {
      this.loading = true;
      try {
        let res = await xhr.post("/reset", { email: this.email })
        console.log("res.data", res.data);
        if (!res.data || res.data === null) {
          this.snackbar = true;
          this.loading = false;
        } else {
          this.loading = false;
          this.finished = true
        }
      } catch (error) {
        console.log("error", error);
      }

    },
  },
};
</script>